<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="650px">
    <template #activator="{ on }">
      <v-btn depressed small v-on="on" color="primary" class="ml-2">
        <v-icon>add</v-icon>
        {{ createText }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dense flat dark color="primary" v-if="!loading">
        <v-btn icon dark @click="cancel">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="pl-0">{{ createDialogTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn type="submit" :loading="creating" :form="formId" small light depressed>
          Guardar
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col v-if="loading" class="d-flex align-center justify-center">
              <v-progress-circular
                class="my-12"
                :size="50"
                :color="['success', 'error', 'warning', 'info'][Math.floor(Math.random() * 4)]"
                indeterminate
              />
            </v-col>
            <form-vjsf
              v-else
              :key="formKey"
              :formId="formId"
              :formData="formData"
              @getFormData="getFormData"
              @submitForm="submitForm"
            />
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    FormVjsf: () => import("@/components/form-vjsf"),
  },
  props: {
    createText: { type: String, required: true },
    crudActions: { type: Object, required: true },
  },
  data: () => ({
    dialog: false,
    formKey: 0,
    formData: {
      id: null,
      model: {},
      schema: {},
      options: {},
    },
    formId: "create_item_" + Date.now(),
    loading: null,
    creating: null,
    createDialogTitle: null,
  }),

  methods: {
    cancel() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.getFormData();
      this.formKey += 1;
    },
    async getFormData() {
      try {
        this.loading = true;
        const res = await this.crudActions.getCreateItemForm();
        const { title } = res.data;

        this.formData = res.data.vjsfData;
        this.createDialogTitle = title ? title : "Nuevo ítem";
        this.loading = false;
      } catch {
        this.$notifyError();
      }
    },
    async submitForm() {
      try {
        this.creating = true;
        await this.crudActions.createItem(this.formData.model);
        this.$emit("itemCreated");
        this.dialog = false;
        this.resetForm();
        this.$notifySuccess();
      } catch {
        this.$notifyError();
      } finally {
        this.creating = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
